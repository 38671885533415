<template>
  <div class="listing">
    <div class="title">
      <h2>第三方SDK类服务商目录</h2>
    </div>
    <div>本版更新时间：2022年【9】月【1】日</div>
    <br />
    <div>
      为保障游戏服务的相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包（SDK）或类似应用程序实现相关目的。我们会对合作方获取信息的软件工具开发包（SDK）或类似应用程序进行严格的安全评估，以保护您的个人信息安全。以下是我们接入的主要的第三方服务商的信息
    </div>
    <br />
    <div>
      <div class="bold"><span class="dian">•</span>微信 SDK</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、GAID、Android
        ID、Serial，iOS如IDFV）、MAC地址、WLAN接入点、分享图片或内容
      </div>
      <div>使用目的：支持微信登录和分享</div>
      <div>使用场景：在用户使用微信登录或者分享时使用</div>
      <div>主体：深圳市腾讯计算机系统有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://open.weixin.qq.com/"
          target="_Self"
          >https://open.weixin.qq.com/</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
          target="_Self"
          >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>微信支付</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、GAID、Android
        ID、Serial）、MAC地址、WLAN接入点
      </div>
      <div>使用目的：支持微信支付</div>
      <div>使用场景：在用户使用微信支付时使用</div>
      <div>主体：财富通支付科技有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://www.tenpay.com/v4/index.html"
          target="_Self"
          >https://www.tenpay.com/v4/index.html</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml"
          target="_Self"
          >https://www.tenpay.com/v3/helpcenter/low/privacy.shtml</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>支付宝</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、GAID、MEID、IMSI、Android
        ID、Serial、ICCID、OAID，iOS如IDFV、IDFA）、MAC地址、IP地址、位置信息（如WLAN接入点、蓝牙）、设备传感器
      </div>
      <div>使用目的：帮助用户在应用内使用支付宝</div>
      <div>使用场景：在用户使用支付宝支付时使用</div>
      <div>主体：支付宝（杭州）信息技术有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://render.alipay.com/p/f/fd-iwwyijeh/index.html"
          target="_Self"
          >https://render.alipay.com/p/f/fd-iwwyijeh/index.html</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://docs.open.alipay.com/54"
          target="_Self"
          >https://docs.open.alipay.com/54</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>移动一键登录</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、GAID、IMSI、ICCID、Android
        ID，iOS如IDFV）、IP地址、蓝牙、MAC地址、本机号码
      </div>
      <div>使用目的：一键登录</div>
      <div>使用场景：在用户使用移动手机号一键登录时使用</div>
      <div>主体：中移互联网有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="http://dev.10086.cn/numIdentific"
          target="_Self"
          >http://dev.10086.cn/numIdentific</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://wap.cmpassport.com/resources/html/contract.html"
          target="_Self"
          >https://wap.cmpassport.com/resources/html/contract.html</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>联通一键登录</div>
      <div>
        涉及个人信息：设备标识符（如IMEI、GAID、IMSI）、IP地址、MAC地址、SSID、蓝牙、本机号码
      </div>
      <div>使用场景：在用户使用联通手机号一键登录时使用</div>
      <div>主体：中国联合网络通信有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://onlinebusiness.10010.com/product/5302?chnl=none"
          target="_Self"
          >https://onlinebusiness.10010.com/product/5302?chnl=none</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html"
          target="_Self"
          >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>电信一键登录</div>
      <div>
        涉及个人信息：设备标识符（Android如Android
        ID、IMSI、GAID）、MAC地址、IP地址、蓝牙、本机号码
      </div>
      <div>使用目的：一键登录</div>
      <div>使用场景：在用户使用电信手机号一键登录时使用</div>
      <div>主体：世纪龙信息网络有限责任公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="http://id.189.cn/api?initialSrc=/html/api_detail_634.html"
          target="_Self"
          >http://id.189.cn/api?initialSrc=/html/api_detail_634.html</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl="
          target="_Self"
          >https://e.189.cn/sdk/agreement/content.do?type=main&appKey=&hidetop=true&returnUrl=</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>极光一键登录</div>
      <div>
        涉及个人信息：设备标识符（Android如Android
        ID、IMSI、GAID）、MAC地址、IP地址、蓝牙、本机号码
      </div>
      <div>使用目的：一键登录</div>
      <div>使用场景：在用户使用手机号一键登录时使用</div>
      <div>主体：深圳市和讯华谷信息技术有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://www.jiguang.cn/"
          target="_Self"
          >https://www.jiguang.cn/</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://www.jiguang.cn/license/privacy"
          target="_Self"
          >https://www.jiguang.cn/license/privacy</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>极光推送</div>
      <div>
        涉及个人信息：设备参数及系统信息、设备标识符（Android如Android
        ID、IMSI、GAID）、网络信息与位置信息、应用列表信息、推送信息日志
      </div>
      <div>使用目的：推送消息和通知</div>
      <div>使用场景：在用户打开订阅通知开关时，会对用户进行个性化推送</div>
      <div>主体：深圳市和讯华谷信息技术有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://www.jiguang.cn/"
          target="_Self"
          >https://www.jiguang.cn/</a
        >
      </div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://www.jiguang.cn/license/privacy"
          target="_Self"
          >https://www.jiguang.cn/license/privacy</a
        >
      </div>
      <div class="bold">极光推送自启动和关联启动说明</div>
      <div>
        1、为确保本游戏应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本游戏应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本游戏应用自启动或关联启动行为，是因实现功能及服务所必要的。
      </div>
      <div>
        2、当您打开本游戏内容类推送消息，在征得您的明确同意后，会跳转本游戏打开相关内容。在未征得您同意的情况下，则不会有关联启动。
      </div>
      <div>3、当您打开本游戏内部下载的文件后，会关联启动第三方APP。</div>
      <br />

      <div class="bold"><span class="dian">•</span>广点通 SDK</div>
      <div>推送广告服务</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：向用户推送广告信息、广告归因、反作弊</div>
      <div>使用场景：在向用户推送广告时使用</div>
      <div>主体：深圳市腾讯计算机系统有限公司</div>
      <div>收集方式：接口传输</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://e.qq.com/optout.html"
          target="_Self"
          >https://e.qq.com/optout.html</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>今日头条广告 SDK</div>
      <div>推送广告服务</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：向用户推送广告信息、广告归因、反作弊</div>
      <div>使用场景：在向用户推送广告时使用</div>
      <div>主体：北京巨量引擎网络技术有限公司</div>
      <div>收集方式：接口传输</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://www.oceanengine.com/"
          target="_Self"
          >https://www.oceanengine.com/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>穿山甲广告 SDK</div>
      <div>推送广告服务</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：向用户推送广告信息、广告归因、反作弊</div>
      <div>使用场景：在向用户推送广告时使用</div>
      <div>主体：北京巨量引擎网络技术有限公司</div>
      <div>收集方式：接口传输</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://www.csjplatform.com/privacy"
          target="_Self"
          >https://www.csjplatform.com/privacy</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>热云数据TrackingIO SDK</div>
      <div>数据统计</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：广告投放及数据监测、上报</div>
      <div>使用场景：用户正常运行操作游戏时</div>
      <div>主体：北京热云科技有限公司</div>
      <div>收集方式：接口传输</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://new.trackingio.com/#/policy"
          target="_Self"
          >https://new.trackingio.com/#/policy</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span> 数数 SDK</div>
      <div>数据统计</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：数据统计</div>
      <div>使用场景：用户正常运行操作游戏时</div>
      <div>主体：数数信息科技(上海)有限公司</div>
      <div>收集方式：接口传输</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://docs.thinkingdata.cn/ta-manual/v3.7/installation/installation_menu/privacy_cn.html"
          target="_Self"
          >https://docs.thinkingdata.cn/ta-manual/v3.7/installation/installation_menu/privacy_cn.html</a
        >
      </div>
      <br />

      <div class="bold">
        <span class="dian">•</span>腾讯bugly崩溃日志上报 SDK
      </div>
      <div>崩溃日志上报</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、GAID、MEID，iOS如IDFV、IDFA）、MAC地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
      </div>
      <div>使用目的：崩溃日志上报</div>
      <div>运营方：深圳市腾讯计算机系统有限公司</div>
      <div>收集方式：SDK采集</div>
      <div>
        隐私政策：<a
          class="agreement"
          href="https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf"
          target="_Self"
          >https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>U8 SDK</div>
      <div>渠道SDK接入框架，提供基础服务</div>
      <div>
        涉及个人信息：设备标识符（Android如IMEI、Android
        ID、OAID、IMSI、GAID、ICCID、MEID、Serial，iOS如IDFV、IDFA）、MAC地址、IP地址、位置信息（如GPS、WLAN接入点、蓝牙和基站）、设备传感器
        、应用列表
      </div>
      <div>
        使用目的：提供基本网络服务、安全风控、快捷接入发行渠道及其他服务功能
      </div>
      <div>使用场景：在用户使用App时使用</div>
      <div>主体：上海丞诺网络科技有限公司</div>
      <div>收集方式：SDK采集和接口传输</div>
      <div>
        官网链接：<a
          class="agreement"
          href="http://www.u8sdk.com/u8sdk/version3/"
          target="_Self"
          >http://www.u8sdk.com/u8sdk/version3/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>华为渠道SDK</div>
      <div>所属公司 ：华为软件技术有限公司</div>
      <div>
        信息获取 ：设备标识信息（imei、imsi、GAID、iccid、android id、android ad
        id、oaid、SN、mac）、软件安装列表信息
      </div>
      <div>
        权限获取：读取手机状态和身份
        (android.permission.READ_PHONE_STATE)、获取软件安装列表
      </div>
      <div>使用目的：识别渠道用户，保障网络及运营安全</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://developer.huawei.com/consumer/cn/"
          target="_Self"
          >https://developer.huawei.com/consumer/cn/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>小米渠道SDK</div>
      <div>所属公司 ：小米科技有限责任公司</div>
      <div>
        信息获取 ：系统版本、手机样式、
        生成ID、设备标识信息（imei、imsi、GAID、iccid、android id、android ad
        id、oaid、SN、mac）、必要的移动应用列表信息机号码
      </div>
      <div>
        权限获取：读取手机状态和身份 (android.permission.READ_PHONE_STATE)
      </div>
      <div>使用目的：识别渠道用户，保障网络及运营安全</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://dev.mi.com/console/"
          target="_Self"
          >https://dev.mi.com/console/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>OPPO渠道SDK</div>
      <div>所属公司 ：广东欢太科技有限公司</div>
      <div>
        信息获取 ：设备标识信息（imei、imsi、GAID、iccid、android id、android ad
        id、oaid、SN、mac）、必要的移动应用列表信息
      </div>
      <div>
        权限获取：读取手机状态和身份 (android.permission.READ_PHONE_STATE)
      </div>
      <div>
        使用目的：识别渠道用户，保障网络及运营安全，如果用户在游戏内选择注册新的一加账号，注册过程中为了验证用户身份，会读取短信验证码
      </div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://open.oppomobile.com/"
          target="_Self"
          >https://open.oppomobile.com/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>VIVO渠道SDK</div>
      <div>所属公司 ：维沃通信科技有限公司</div>
      <div>信息获取 ：系统版本、手机样式、 生成ID、必要的移动应用列表信息</div>
      <div>
        权限获取：读取手机状态和身份 (android.permission.READ_PHONE_STATE)
      </div>
      <div>使用目的：识别渠道用户，保障网络及运营安全</div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://dev.vivo.com.cn/home"
          target="_Self"
          >https://dev.vivo.com.cn/home</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>九游渠道SDK</div>
      <div>所属公司 ：广州爱九游信息技术有限公司</div>
      <div>
        信息获取 ：设备标识信息（imei、imsi、GAID、iccid、android id、android ad
        id、oaid、SN、mac）
      </div>
      <div>权限获取：读取手机状态和身份、查找设备上的帐户、日历、读取短信</div>
      <div>
        使用目的：设备标识信息和读取手机状态和身份是为了识别渠道用户，保障网络及运营安全，读取短信是为了在支付环节验证用户身份，查找设备上的用户是为了向用户自动推荐游戏好友，日历权限是为了提醒用户参与游戏下载和游戏中的其他重大活动
      </div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://game.open.uc.cn/"
          target="_Self"
          >https://game.open.uc.cn/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>应用宝渠道SDK</div>
      <div>所属公司 ：深圳市腾讯计算机系统有限公司</div>
      <div>
        信息获取 ：系统版本、手机样式、
        生成ID、设备标识信息（imei、imsi、GAID、iccid、android id、android ad
        id、oaid、SN、mac）、手机号码、软件安装列表信息
      </div>
      <div>
        权限获取：读取手机状态和身份
        (android.permission.READ_PHONE_STATE)、读取短信
      </div>
      <div>
        使用目的：读取系统版本、手机样式、
        生成ID、设备标识信息、手机号码、手机状态和身份、MAC地址、软件安装列表信息是为了识别渠道用户，保障网络及运营安全，读取手机号码是为了推送信息。如果用户选择在游戏内进行支付，为了在支付环节验证用户身份，会读取微信支付发送的短信验证码，因此需要读取短信权限。
      </div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://open.tencent.com/"
          target="_Self"
          >https://open.tencent.com/</a
        >
      </div>
      <br />

      <div class="bold"><span class="dian">•</span>QQ SDK</div>
      <div>所属公司：深圳市腾讯计算机系统有限公司</div>
      <div>信息读取：设备标识信息（MAC/Android ID/硬件序列号/GAID）</div>
      <div>
        权限获取：读取手机状态和身份 (android.permission.READ_PHONE_STATE)
      </div>
      <div>
        使用目的：用于QQ账号登录服务、帮助用户分享内容至QQ客户端，保障网络及运营安全
      </div>
      <div>
        官网链接：<a
          class="agreement"
          href="https://open.tencent.com/"
          target="_Self"
          >https://open.tencent.com/</a
        >
      </div>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SDKintro',
  data () {
    return {}
  },
  mounted () {},
  methods: {
    // agreement () {
    //   let routerData = this.$router.resolve({
    //     path: '/children'
    //   })
    //   window.open(routerData.href, '_blank')
    // }
  }
}
</script>

<style lang="less" scoped>
.listing {
  word-break: break-all;
  word-wrap: break-word;
  padding-bottom: 20px;
  .title {
    text-align: center;
  }
  .dian {
    display: inline-block;
    color: royalblue;
    margin-right: 10px;
  }
  .bold {
    font-weight: bold;
  }
  .agreement {
    color: royalblue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
